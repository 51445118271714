import React, { useState, useEffect } from 'react';
import { Box, CardMedia, Typography, Button, Snackbar, Alert, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import SignInDialog from '../SignInDialog';
import axios from 'axios';  // Import Axios
import { useUser } from '../../context/UserContext';
import BookExperienceModal from './BookExperienceModal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {trackBookExperienceClick} from '../../utils/googleAnalyticsReport'
interface ExperienceDetailProps {
    experience: {
        id: string;
        imageUrl: string;
        title: string;
        date: string;
        time: string;
        location: string;
        description: string;
        price: string;
        formatTime: string;
        maxExplorers: number;
        serviceCharge: string;
        pricePerPerson: string;
        cityName: string;
        guideId: string;
        scheduledTimeStamps: any;
        classId: string;
        hosted_by: string;
        availableSeatCount: number;
        cutOffTime: string;
        schedule: {
            startTimestamp: string;
        };
    };
}

const ExperienceDetail: React.FC<ExperienceDetailProps> = ({ experience }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [platform, setPlatform] = useState<string>('');
    const { setUser } = useUser();
    const apiUrl = process.env.REACT_APP_BASE_API_URL || '';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [experiences, setExperiences] = useState<any>({});
    const [guideInfo, setGuideInfo] = useState<any>(null); 
    const [currentImage, setCurrentImage] = useState(experience.imageUrl);
    const [feedbackData, setFeedbackData] = useState<any>({ data: [] });
    const [currentSlide, setCurrentSlide] = useState(0);
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchClassData = async () => {
            if (experience.classId) {
                try {
                    const API_URL = `${process.env.REACT_APP_BASE_API_URL}/v1/class/getClass/${experience.classId}`;
                    const response = await axios.get(API_URL);
                    // const mappedEvents = mapEventData([response.data]);
                    
                    setExperiences(response.data);
                } catch (error) {
                    console.error("Error fetching class data:", error);
                }
            }
        };

        const fetchGuideData = async () => {
            if (experience.guideId) {
                try {
                    const API_URL = `${process.env.REACT_APP_BASE_API_URL}/v1/user/${experience.guideId}`;
                    const response = await axios.get(API_URL);
                    setGuideInfo(response.data); // Store the user data in the state
                } catch (error) {
                    console.error("Error fetching guide/user data:", error);
                }
            }
        };
        const fetchFeedbackData = async () => {
            if (experience.guideId) {
                try {
                    const API_URL = `${process.env.REACT_APP_BASE_API_URL}/v1/class/feedback/${experience.guideId}`;
                    const response = await axios.get(API_URL);
                    setFeedbackData(response.data);
                } catch (error) {
                    console.error("Error fetching feedback data:", error);
                }
            }
        };

        fetchClassData();
        fetchGuideData();
        fetchFeedbackData();     
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('windows') !== -1) {
            setPlatform('windows');
        } else if (userAgent.indexOf('mac') !== -1) {
            setPlatform('mac');
        } else if (userAgent.indexOf('linux') !== -1) {
            setPlatform('linux');
        } else {
            setPlatform('unknown');
        }

    }, []);

    const slidesToShow = isMobile ? 1 : 3;

    const totalSlides = feedbackData?.data ? Math.ceil(feedbackData.data.length / slidesToShow) : 0;

    const handleNext = () => {
        if (currentSlide < totalSlides - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const currentFeedbacks = feedbackData?.data?.slice(
        currentSlide * slidesToShow,
        (currentSlide + 1) * slidesToShow
    ) || [];


    const handleBookClass = () => {
        trackBookExperienceClick(experience.classId,experience.title)
        if (localStorage.getItem('authToken')) {
            if (experience.availableSeatCount > 0) {
                if (experience.cutOffTime && experience.cutOffTime !== 'none') {
                    const cutOffHours = +experience.cutOffTime.split(' ')[0];
                    const startTime = moment(experience.scheduledTimeStamps[0].startTimeStamp);
                    const now = moment(); // Use moment for consistency
                    const hoursDifference = startTime.diff(now, 'hours');
                    if (hoursDifference < cutOffHours) {
                        setSnackbarMessage('Sign up for this experience has closed. Check out our other experiences!');
                        setSnackbarOpen(true);
                        return;
                    }
                } else if (experience.cutOffTime === 'none') {
                    const endTime = moment(experience.scheduledTimeStamps[0].endTimeStamp); // Adjust if the field name is different
                    const now = moment(); // Use moment for consistency
                    if (now.isAfter(endTime)) {
                        setSnackbarMessage('Sign up for this experience has closed. Check out our other experiences!');
                        setSnackbarOpen(true);
                        return;
                    }
                }
            }
            if (experience.availableSeatCount > 0) {
                // Proceed with booking if seats are available
                setIsModalOpen(true);
            } else {
                setSnackbarMessage('The experience is already full, please explore other experiences.');
                setSnackbarOpen(true);
            }
        } else {
            // User is not authenticated, show sign-in dialog
            setIsDialogOpen(true);
        }
    };

    const handleSignInSuccess = async (userInfo: any) => {
        const userData = JSON.stringify(userInfo);
        localStorage.setItem("userInfo", userData);
        setUser(userInfo);

        const data = {
            userHandle: userInfo.name.replace(/ /g, ''),
            firstName: userInfo.given_name,
            lastName: userInfo.family_name,
            email: userInfo.email.toLowerCase(),
            identities: [
                {
                    identityType: 'Google',
                    identityValue: userInfo.id,
                },
            ],
            operatingSystem: platform,
        };

        try {
            // Call the API to create a user
            const response = await axios.post(`${apiUrl}/v1/user/createUser`, data);

            if (response.status === 201 || response.status === 200) {
                console.log('User created successfully:', response.data);
                // Store the token in local storage
                localStorage.setItem('authToken', response.data.token);
                const userData = JSON.stringify(response.data);
                localStorage.setItem("user", userData)
                // setIsModalOpen(true)
                handleBookClass()
                
            } else {
                console.log('Failed to create user:', response.data);
            }
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false); // Close the dialog
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' }, // Column for mobile, row for desktop
                padding: 2,
                width: '100%',
                height: 'auto', // Adjust height to fit content
                overflow: 'hidden', // Prevent scrollbars
                gap: 2,
                boxSizing: 'border-box', // Include padding and border in element's total width and height
                paddingTop: 7
            }}
        >
            {/* Left Side */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    // padding: 2,
                    flex: 1,
                    textAlign: 'left',
                    maxWidth: { xs: '100%', sm: '33.33%' }, // Responsive width
                    boxSizing: 'border-box', // Prevent overflow due to padding

                }}
            >
                <Link to="/experiences" style={{ textDecoration: 'none', }}>
                    <Button
                        sx={{
                            marginBottom: 2,
                            color: 'black',
                            textTransform: 'none',
                            fontSize: '18px',
                            marginTop: isMobile ? '-40px' : "-25px",
                            '&:hover': {
                                backgroundColor: 'transparent', // Remove the hover background
                                boxShadow: 'none', // Remove any shadow
                            }
                        }}
                        startIcon={<ArrowBackIcon />}
                    >
                        All experiences
                    </Button>
                </Link>
                <Typography variant="h4" component="div"  >
                    {experience.title}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 3 }}>
                    {experience.date} {experience.time}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    {experience.location}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: experiences.classLocation?.directions ? 1 : 0 }}>
                {experiences.classLocation?.directions ?  experiences.classLocation?.directions : ""}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    Guide: {guideInfo?.firstName} {guideInfo?.lastName}
                </Typography>  
            </Box>

            {/* Center Image */}
            <Box
                sx={{
                    position: 'relative', // Allows for absolute positioning of thumbnails
                    marginTop: isMobile ? 2 : 5,
                    width: { xs: '100%', sm: 'auto' },
                        height: { xs: 'auto', sm: '80%' },
                    maxWidth: { sm: 600 }, // Constrain width for desktop
                    }}
                >
                    {/* Image */}
                    <CardMedia
                        component="img"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                                // flex: 1,
                        }}
                       image={currentImage}
                        alt={experiences.title}
                    />

                {/* Overlay Logic for Sold Out and Booking Closed */}
                {(() => {
                    const now = moment();

                    // Ensure scheduledTimeStamps is defined and has at least one item
                    if (experiences.scheduledTimeStamps && experiences.scheduledTimeStamps.length > 0) {
                        const startTime = moment(experiences.scheduledTimeStamps[0].startTimeStamp);
                        const endTime = moment(experiences.scheduledTimeStamps[0].endTimeStamp);

                        if (experiences.availableSeatCount === 0) {
                            return (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 0, 0, 0.5)', // Red background with 50% opacity
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: '#fff', // White text color
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Sold Out
                                    </Typography>
                                </Box>
                            );
                        }

                        if (experiences.cutOffTime && experiences.cutOffTime !== 'none') {
                            const cutOffHours = +experiences.cutOffTime.split(' ')[0];
                            const hoursDifference = startTime.diff(now, 'hours');
                            if (hoursDifference < cutOffHours) {
                                return (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(255, 0, 0, 0.5)', // Red background with 50% opacity
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: '#fff', // White text color
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Booking Closed
                                        </Typography>
                                    </Box>
                                );
                            }
                        }

                        if (experiences.cutOffTime === 'none' && now.isAfter(endTime)) {
                            return (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 0, 0, 0.5)', // Red background with 50% opacity
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: '#fff', // White text color
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Booking Closed
                                    </Typography>
                                </Box>
                            );
                        }
                    }

                        return null; // No overlay if none of the conditions are met
                    })()}

                {/* Scroller for other images (overlaid on the main image) */}
                {experiences.otherImages && experiences.otherImages.length > 0 && (
                    <Box
                        sx={{
                            position: 'absolute', // Overlay on the main image
                            bottom: 10, // Position it 10px from the bottom of the image
                            left: 0,
                            right: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            overflowX: 'auto', // Allows scrolling if needed
                            padding: '5px 0', // Padding around thumbnails
                            gap: 1, // Spacing between thumbnails
                            background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background for contrast
                        }}
                    >
                        {/* Loop through experiences.otherImages */}
                        {[experience.imageUrl, ...experiences.otherImages].map((imageObj, index) => (
                            <CardMedia
                                key={index}
                                component="img"
                                sx={{
                                    width: !isMobile ? 80 : 60,  
                                    height: !isMobile ? 80 : 60, 
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    borderRadius: 1, 
                                    border: currentImage === (imageObj?.url || imageObj) ? '2px solid #1976d2' : 'none', // Highlight selected image
                                }}
                                image={imageObj?.url || imageObj} // Handle both object with URL and plain string URL
                                alt={`Thumbnail ${index + 1}`}
                                onClick={() => setCurrentImage(imageObj?.url || imageObj)}
                            />
                        ))}
                    </Box>
                )}
                <Box
                    sx={{
                        padding: 2,
                        backgroundColor: theme.palette.background.paper,
                        // boxShadow: theme.shadows[4],
                        // borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    
                </Box>
                    </Box>
                    {/* Right Side */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            padding: isMobile ? 0 : 2,
                            flex: 2,
                            textAlign: 'left',
                            maxWidth: { xs: '100%', sm: '33.33%' }, // Responsive width
                            boxSizing: 'border-box', // Prevent overflow due to padding
                        }}
                    >



                        <Typography variant="body1" sx={{ marginTop: 2 }}>
                            {experience.description.split('\n').map((paragraph, index) => (
                                <p key={index}>
                                    {paragraph}
                                </p>
                            ))}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: experiences?.additionalReqComments ? 1 : 0, fontWeight:'800' }}>
                        {experiences.additionalReqComments ? "What should you bring?:" : ""}
                        </Typography>
                        <Typography variant="body2" sx={{marginTop: experiences?.additionalReqComments ? 1 : 0,fontWeight:'800'}}></Typography> {experiences?.additionalReqComments ? experiences?.additionalReqComments: ""}
                        {guideInfo?.guideBio && (
                            <>
                                <Typography variant="body2" sx={{ marginTop: 2, fontWeight: '800' }}>
                                    About {experience?.hosted_by}:
                                </Typography>
                                <Typography variant="body2" sx={{ marginTop: 1 }}>
                                    {guideInfo.guideBio}
                                </Typography>
                            </>
                        )}
                        <Typography variant="body2" sx={{ marginTop: 2 }}>
                            {experience.price}
                        </Typography>
                        <Button
                            sx={{
                                marginTop: 2,
                                backgroundColor: '#2D3178',
                                width: isMobile ? '60%': '40%',
                                color: 'white',
                                borderRadius: '30px',
                                padding: '6px 5px',
                                textTransform: 'none',
                                fontSize: '0.95rem',
                                '&:hover': {
                                    backgroundColor: 'darkgrey',
                                }
                            }}
                            onClick={handleBookClass}
                        >
                            Book Experience
                        </Button>
                        <SignInDialog
                            open={isDialogOpen}
                            onClose={handleCloseDialog}
                            onSignInSuccess={handleSignInSuccess}
                        />
                        <BookExperienceModal open={isModalOpen} handleClose={handleCloseModal} experienceData={experience} />
                    </Box>
                    <Snackbar open={snackbarOpen} autoHideDuration={3000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        onClose={handleCloseSnackbar}  sx={{ marginTop: 8 }} >
                        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems:'flex-start', }}>
            {/* Title */}
            {feedbackData?.data && feedbackData.data.length > 0 && (
            <Typography variant="h5" component="div" sx={{ marginBottom: 3, marginLeft: '20px' }}>
                Explorer's Reviews:
            </Typography>
            )}

            {feedbackData?.data && feedbackData.data.length > 0 && (
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', position: 'relative', justifyContent:'center' }}>
                    {/* Left Arrow */}
                    <IconButton
                        onClick={handlePrev}
                        disabled={currentSlide === 0}
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            color: currentSlide === 0 ? 'gray' : 'black', // Disabled style
                            visibility: 'visible', // Always visible
                        }}
                    >
                        <ArrowBackIosIcon fontSize="large" />
                    </IconButton>

                    {/* Carousel Content */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 3,
                            overflow: 'hidden',
                            width: '100%', // Full width for the container
                            maxWidth: '80%', // Make sure it stays between arrows
                        }}
                    >
                        {currentFeedbacks.map((item: any, index: number) => (
                        <Box
                                key={index}
                                sx={{
                                    width: isMobile ? '100%' : '30%',
                                    marginBottom: 3,
                                    backgroundColor: '#FFFFFF',
                                    padding: 2,
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Typography variant="h6" component="div" gutterBottom>
                                    {item.classTitle}
                                </Typography>
                                {item.feedbackData.map((feedback: any, feedbackIndex: number) => (
                                    <Box key={feedbackIndex} sx={{ marginTop: 2 }}>
                                        <Typography variant="body2">
                                            {feedback.explorerFeedbackTextToGuide}
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                                            <Typography variant="body2" fontWeight="bold">
                                                {feedback.explorerFirstName} {feedback.explorerLastName}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {moment(feedback.createdAt).format('MMMM Do YYYY')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>

                    {/* Right Arrow */}
                    <IconButton
                        onClick={handleNext}
                        disabled={currentSlide >= totalSlides - 1}
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            color: currentSlide >= totalSlides - 1 ? 'gray' : 'black', // Disabled style
                            visibility: 'visible', // Always visible
                        }}
                    >
                        <ArrowForwardIosIcon fontSize="large" />
                    </IconButton>
                </Box>
            )}
        </Box>
        </>
    );
};

export default ExperienceDetail;
