import React, { useState,useEffect } from 'react';
import { AppBar, Toolbar, Box, Button, Typography, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { ReactComponent as Logo } from '../assets/images/Moonlight_Black.svg'; // Adjust path based on where your SVG is
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import SignInDialog from './SignInDialog';
import { googleLogout } from '@react-oauth/google';
import axios from 'axios';  // Import Axios
import { useUser } from '../context/UserContext';
const Header: React.FC = () => {
  const apiUrl = process.env.REACT_APP_BASE_API_URL;
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const pathSegments = pathname.split('/').filter(Boolean);
  const experiences = pathSegments[0]; // This will be 'experiences'
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [platform, setPlatform] = useState('');
  const { user, setUser } = useUser();
  const [activeSection, setActiveSection] = React.useState<string>('');
  
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('windows') !== -1) {
      setPlatform('windows');
    } else if (userAgent.indexOf('mac') !== -1) {
      setPlatform('mac');
    } else if (userAgent.indexOf('linux') !== -1) {
      setPlatform('linux');
    } else {
      setPlatform('unknown');
    }
    const userData:any = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(userData);
    setUser(userInfo)
    if(experiences === 'experiences'){
      setActiveSection('experiences')
    }
  }, [setUser,experiences]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const redirectToPrivate = () => {
    window.location.href = 'https://themoonlightcollective.com/private';
  };
  const redirectToBlog = () => {
    window.location.href = 'https://themoonlightcollective.com/blog';
  };
  const redirectToAbout = () => {
    window.location.href = 'https://themoonlightcollective.com/about';
  };
  const redirectToHome = () => {
    window.location.href = 'https://themoonlightcollective.com';
  };
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    if (isMobile) {
      setMobileOpen(false);
    }
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleSignInSuccess = async (userInfo: any) => {
    const userData = JSON.stringify(userInfo);
    localStorage.setItem("userInfo", userData);
    setUser(userInfo); // Store user data, including given_name
    const data = {
      userHandle: userInfo.name.replace(/ /g, ''),
      firstName: userInfo.given_name,
      lastName: userInfo.family_name,
      email: userInfo.email.toLowerCase(),
      identities: [
        {
          identityType: 'Google',
          identityValue: userInfo.id,
        },
      ],
      operatingSystem: platform,
    };
    try {
      // Call the API to create a user
      const response = await axios.post(`${apiUrl}/v1/user/createUser`, data);
      if (response.status === 201 || response.status === 200) {
        console.log('User created successfully:', response.data);
        const responseData = response.data;
        const userData = JSON.stringify(response.data);
        localStorage.setItem("user", userData)
        // Store the token in local storage
        localStorage.setItem('authToken', responseData.token);
      } else {
        console.log('Failed to create user:', response.data);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };
  const handleLogout = () => {
    googleLogout(); // Perform Google logout
    setUser(null); // Clear user data and reset state
    localStorage.removeItem('authToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('user');
    navigate('/experiences')
    if (isMobile) {
      setMobileOpen(false);
    }
    window.location.reload();
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Logo style={{ width: 100, height: 30, margin: '16px 0' }} />
      <List>
      {user && (
          <ListItem>
            <Typography 
              variant="h6" 
              style={{fontSize: '1.25rem' }}  // Adjust fontSize as needed
            >
              {`Welcome ${user.given_name}`}
            </Typography>
          </ListItem>
        )}
        <ListItem button onClick={() => navigate('/experiences')}>
          <ListItemText primary="Experiences" />
        </ListItem>
        <ListItem button onClick={redirectToPrivate}>
          <ListItemText primary="Private" />
        </ListItem>
        <ListItem button onClick={redirectToBlog}>
          <ListItemText primary="Blog" />
        </ListItem>
        <ListItem button onClick={redirectToAbout}>
          <ListItemText primary="About" />
        </ListItem>
        {user ? (
          <ListItem button onClick={handleLogout}>
            <ListItemText primary="SIGN OUT" />
          </ListItem>
        ) : (
          <ListItem button onClick={handleOpenDialog}>
            <ListItemText primary="SIGN IN" />
          </ListItem>
        )}
      </List>
    </Box>
  );
  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      style={{
        background: "#FFFFFF",
        borderBottom: "1px solid #E0E0E0",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 35px 0px",
        padding:'0 10px'
      }}
    >
      <Toolbar>
        {isMobile ? (
          <>
            <Box display="flex" justifyContent="flex-start" sx={{ flex: 1 }}>
              <Logo style={{ width: 100, height: 40 }} />
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ position: 'absolute', right: 5 }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
              }}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <>
            <Box display="flex" alignItems="center" onClick={redirectToHome}
              sx={{
                flexBasis: '25%',
                display: 'flex',
                justifyContent: 'flex-start',
                cursor: 'pointer',
              }}
            >
              <Logo style={{ width: 135, marginRight: 8 }} />
            </Box>
            <Box display="flex" justifyContent="center"
              sx={{
                flexBasis: '50%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                color="inherit"
                onClick={() => { setActiveSection('experiences'); navigate('/experiences'); }}
                style={{
                  textDecoration:
                    activeSection === "experiences" ? "underline" : "none",
                  textTransform: "capitalize",
                  textUnderlineOffset: "8px",
                  fontSize: "15px",
                  fontWeight: "normal", 
                  margin: "0 12px",
                  letterSpacing: "0.5px",
                }}
              >
                Experiences
              </Button>

              <Button
                color="inherit"
                onClick={redirectToPrivate}
                style={{
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: "normal",
                  margin: "0 12px", 
                  letterSpacing: "0.5px",
                }}
              >
                Private
              </Button>

              <Button
                color="inherit"
                onClick={redirectToBlog}
                style={{
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: "normal",
                  margin: "0 12px",
                  letterSpacing: "0.5px",
                }}
              >
                Blog
              </Button>

              <Button
                color="inherit"
                onClick={redirectToAbout}
                style={{
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: 300,
                  margin: "0 12px",
                  letterSpacing: "0.5px",
                }}
              >
                About
              </Button>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center"
              sx={{
                flexBasis: '25%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {user ? (
                <>
                  <Typography variant="body2" sx={{ marginRight: 2 }}>
                    Welcome {user.given_name}
                  </Typography>
                  <Button color="inherit" onClick={handleLogout}>
                    Sign out
                  </Button>
                </>
              ) : (
                <Button color="inherit" 
                 onClick={handleOpenDialog}>
                 Sign in
                </Button>
              )}
            </Box>
          </>
        )}
        <Box flexGrow={1} />
      </Toolbar>
      <SignInDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSignInSuccess={handleSignInSuccess}
      />
    </AppBar>
  );
};
export default Header;