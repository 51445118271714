import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import theme from '../src/theme/theme';
import Experiences from './pages/ExperiencesList';
import ExperienceDetail from './pages/ExperienceDetail';
import Header from './components/Header';
import { PublicRoute } from "./routes/intex";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
function App() {
  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/experiences" replace />}
            />
            <Route
              path="/experiences"
              element={<PublicRoute element={<Experiences />} restricted={false} />}
            />
            <Route
              path="/experiences/:id"
              element={<PublicRoute element={<ExperienceDetail />} restricted={false} />}
            />
            {/* Add more routes as needed */}
          </Routes>
        </Router>
      </ThemeProvider>
    </Elements>
  );
}

export default App;
