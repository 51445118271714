import TickIcon from '../../assets/images/success.png'; // Adjust the path as needed
import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Grid, Box, Typography, DialogActions, Divider, Link, Snackbar, Alert } from '@mui/material';
import { STRINGS } from '../../constants/strings';
import axios from 'axios';
import PaymentForm from '../PaymentForm';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {trackCompletedTransaction} from '../../utils/googleAnalyticsReport';
interface ExperienceData {
  id: string;
  imageUrl: string;
  title: string;
  date: string;
  time: string;
  location: string;
  description: string;
  price: string;
  formatTime: string;
  maxExplorers: number;
  serviceCharge: string;
  pricePerPerson: string;
  cityName: string;
  guideId: string;
  classId: string;
  scheduledTimeStamps: any;
}
interface BookExperienceModalProps {
  open: boolean;
  handleClose: () => void;
  experienceData: ExperienceData;
}
interface BookingData {
  classId: string;
  scheduleId: string;
  reBook: boolean;
  finalPriceAfterDiscount: string;
  seatCount: number;
  finalPrice: string;
  finalServiceCharge: string;
}

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
}
const createOrder = async (bookingId: any, grandTotal: any): Promise<any> => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/v1/booking/create-order/${bookingId}?finalPriceAfterDiscount=${grandTotal.toFixed(2)}`;
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.post(url, {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }


    );

    return response.data;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw new Error('Failed to create booking');
  }
};

const BookExperienceModal: React.FC<BookExperienceModalProps> = ({ open, handleClose,experienceData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [spots, setSpots] = useState<number>(1);
  const [isopen, setOpen] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState<any>(null);
  const [apiError, apiSetError] = useState(null);
  const [isError, issetError] = useState(false);
  const createBooking = async (bookingData: BookingData): Promise<any> => {
    const token = localStorage.getItem('authToken');
    const url = `${process.env.REACT_APP_BASE_API_URL}/v1/booking/bookClass?scheduleId=${bookingData.scheduleId}`;
    try {
      const response = await axios.post(url, {
        classId: bookingData.classId,
        reBook: bookingData.reBook,
        finalPriceAfterDiscount: bookingData.finalPriceAfterDiscount,
        seatCount: bookingData.seatCount,
        finalPrice: bookingData.finalPrice,
        finalServiceCharge: bookingData.finalServiceCharge,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }


      );

      return response.data;
    } catch (error: any) {
      let errors = error?.response?.data?.message;
      if (error?.response?.data?.message === 'Class already booked by explorer') {
        errors = 'You’ve already booked this experience!'
      }
      if (error?.response?.data?.message === 'Cannot exceed max explorer limit, remaining 0 seats') {
        errors = 'The experience is already full, please explore other experiences.'
      }
      apiSetError(errors);
      issetError(true);
      // console.error('Error creating booking:', error?.response?.data?.message);
      // throw new Error('Failed to create booking');
    }
  };

  const handleIncrement = (): void => {
    if (spots < experienceData.maxExplorers) { // Ensure spots do not exceed maxExplorers
      setSpots(spots + 1);
    }
  };


  const handleDecrement = (): void => {
    if (spots > 1) {
      setSpots(spots - 1);
    }
  };

  const handleAlartClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
  };
  const handlePaymentSuccess = () => {
    setSuccess(true);
    handleClose();
    setShowPayment(false);
    trackCompletedTransaction(experienceData.classId,experienceData.title,grandTotal.toFixed(2))
  };
  const handlePaymentError = (message: string) => {
    setError(message);
    setShowPayment(false);
  };
  const handleErrorAlartClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    issetError(false);
  };
  const handleConfirm = async () => {
    let user: any = localStorage.getItem('user');
    if (user) {
      let userData = JSON.parse(user)
      if (userData.userId !== experienceData.guideId) {
        let response = await createBooking({
          classId: experienceData.classId,
          scheduleId: experienceData.scheduledTimeStamps.length ? experienceData.scheduledTimeStamps[0].scheduleId : '0',
          reBook: false,
          // reBook: rebook ? true : false,
          finalPriceAfterDiscount: grandTotal.toFixed(2),
          seatCount: spots,
          finalPrice: grandTotal.toFixed(2),
          finalServiceCharge: serviceCharge.toFixed(2),
        });
        if (response) {
          let bookingId = response.bookingId;
          let res = await createOrder(bookingId, grandTotal)
          let clientSecret = res.stripeIntent.clientSecret       
          setShowPayment(true)
          const paymentData = {
            bookingId,
            clientSecret,
          }
          setPaymentData(paymentData);
        }


      } else {
        setOpen(true);
      }
    }



  }
  const handleCloseSuccess = () => {
    setSuccess(false);
  };
  const price = parseFloat(experienceData.pricePerPerson) * spots || 0; // Default to 0 if NaN
  const serviceCharge = parseFloat(experienceData.serviceCharge) * spots || 0; // Default to 0 if NaN

  const grandTotal = price + serviceCharge;
  // const pricePerPerson = parseFloat(experienceData.pricePerPerson)
  // const subtotal = pricePerPerson * spots;

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle ml={2}>{STRINGS.bookClass.title}</DialogTitle>
        <DialogContent>
          <Box p={2}>
            <Grid container spacing={isMobile ? 1 : 2}>
              <Grid item xs={12} sm={4}>
                <img src={experienceData.imageUrl} alt="Experience" style={{ width: '100%', borderRadius: '8px' }} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="h5">{experienceData.title}</Typography>
                <Typography variant="subtitle1">{experienceData.cityName}</Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{STRINGS.bookClass.date}</Typography>
            <Typography variant="body1">{experienceData.formatTime}</Typography>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{STRINGS.bookClass.location}</Typography>
            <Typography variant="body1">
              Location & address will be sent to you once the experience has been booked.
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{STRINGS.bookClass.noOfSpots}</Typography>
            <Box display="flex" alignItems="center" mt={1}>
              <Button variant="outlined" onClick={handleDecrement}>-</Button>
              <Typography variant="h6" mx={2}>{spots}</Typography>
              <Button variant="outlined" onClick={handleIncrement}>+</Button>
            </Box>
            <Typography sx={{ mt: 2 }} variant="body2">{STRINGS.bookClass.groupSize}: {experienceData.maxExplorers}</Typography>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{STRINGS.bookClass.priceDetails}</Typography>
            <Box display="flex" justifyContent="space-between" mt={1}>
              <Typography variant="body1">{STRINGS.bookClass.pricePerPerson}</Typography>
              <Typography variant="body1">${price.toFixed(2)}</Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" justifyContent="space-between" mt={1}>
              <Typography variant="body1">{STRINGS.bookClass.subTotal}</Typography>
              <Typography variant="body1">${price.toFixed(2)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={1}>
              <Typography variant="body1">{STRINGS.bookClass.serviceCharge}</Typography>
              <Typography variant="body1">${serviceCharge.toFixed(2)}</Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box display="flex" justifyContent="space-between">
              <Typography sx={{fontWeight:'500'}} variant={isMobile ? 'body1' : 'h6'}>{STRINGS.bookClass.grandTotal}</Typography>
              <Typography sx={{fontWeight:'500'}} variant={isMobile ? 'body1' : 'h6'}>${grandTotal.toFixed(2)}</Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Typography variant="body2" color="textSecondary">
              <Link
                href="https://themoonlightcollective.com/moonlight-policy"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"  // Always show the underline
                color="inherit"
              >
                <strong>Cancellation Policy</strong>
              </Link>
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography>
              By confirming and booking this experience, you agree to adhere to The Moonlight Collective's Terms & Conditions and Policy.
            </Typography>
            <Typography variant="body2" color="error" mt={2}>
              Disclaimer: Please wait while your payment is being processed, do not press the back button or refresh the page.
            </Typography>
          </Box>
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleClose} color="secondary">Close</Button>
          <Button
            variant="contained"
            sx={{
              ml: 2,
              mr: 5,
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '40px',
              padding: '6px 12px',
              textTransform: 'none',
              fontSize: '0.75rem',
              '&:hover': {
                backgroundColor: 'darkgrey',
              }
            }}
            onClick={handleConfirm}
          >
            Confirm & Pay
          </Button>
        </Box>
      </Dialog>
      <Snackbar
        open={isopen}
        autoHideDuration={3000}
        onClose={handleAlartClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: 8 }}
      >
        <Alert onClose={handleAlartClose} severity="error" sx={{ width: '100%' }}>
          Oops! you cannot book your own experience
        </Alert>
      </Snackbar>
      {showPayment && (
        <Dialog open={showPayment} onClose={() => setShowPayment(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Payment</DialogTitle>
          <DialogContent>
            <PaymentForm
              amount={grandTotal}
              onSuccess={handlePaymentSuccess}
              onError={handlePaymentError}
              paymentData={paymentData}
            />
          </DialogContent>
        </Dialog>
      )}
      {success && (<SuccessDialog open={success} onClose={handleCloseSuccess} />
      )}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={3000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
            Payment Error: {error}
          </Alert>
        </Snackbar>
      )}
      <Snackbar open={isError} autoHideDuration={2000} onClose={handleErrorAlartClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ mt: 2 }} >
        <Alert onClose={handleErrorAlartClose} severity="error" sx={{ width: '100%' }}>
          {apiError}
        </Alert>
      </Snackbar>
    </div>
  );
};
const SuccessDialog: React.FC<SuccessDialogProps> = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/experiences'); // Redirect to home
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs" // Adjust maxWidth for a narrower dialog
      PaperProps={{
        style: {
          height: '400px', // Set a fixed height to make it more vertical
          width: '300px', // Adjust the width for a rectangular shape
        },
      }}
    >
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%" // Ensure content is centered vertically
        >
          <img src={TickIcon} alt="Success" style={{ width: 100, height: 100, marginBottom: 16 }} />
          <DialogTitle style={{ textAlign: 'center' }}>Success</DialogTitle>

          <Typography variant="body1" align="center">
            You have successfully booked the experience
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%" marginBottom={5}>
          <Button variant="contained" sx={{
            backgroundColor: '#2D3178',
            '&:hover': {
              backgroundColor: 'darkgrey',
            }
          }} onClick={handleHomeClick}>
            Home
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default BookExperienceModal;
